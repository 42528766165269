import React from "react";
import MainPageTemplate from "../element/MainPageTemplate";
import ServicePageBanner from "../component/ServicePageBanner";
import ServicePagesComponent from "../component/ServicePagesComponent";
import ServicesSection from "../component/ServicesSection";
import FooterSection from "../component/FooterSection";
import { Helmet, HelmetProvider } from "react-helmet-async";

const CorporateAstrology = () => {
  const content = {
    title1: "Corporate Astrology:",
    description1:
      "Corporate Astrology or Business Astrology refers to applying techniques and strategies in corporate life to gain success. It makes decision-making skills stronger and helps to make the right investment in a business. Through corporate astrology, businesses can gain profit and know about the high risk in the future and how to overcome the hurdles. It helps in business expansion, joining new ventures, and much more. Before making any big decision, astrologers are consulted to know the good and bad effects of the new project or any venture. By studying the planets and other details, astrologers predict the chances of success and possible shortcomings. Many businesses face failure or rejections in the market due to the effect of the bad planets. Corporate astrology can help businesses to reduce the chances of failure and provide the best solution.",
    title2: "Corporate Astrology:",
    description2:
      "With the knowledge of this, you can prepare yourself for the challenges and opportunities in the business and know about the positive and negative developments in the business. Apply the right techniques and kickstart your corporate life with a boost and get quick success. Get the right resources and use the corporate astrology principles and strategies to reach the highest potential in your life.",
  };
  return (
    <HelmetProvider>
      <Helmet>
        <title>Corporate Astrology</title>
        <meta
          name="description"
          content="Welcome to Aradhya Gems by Dr. Sridev Shastri, Best Astrologer in Howrah. A President Awardee Astrologer.
"
        />
      </Helmet>
      <MainPageTemplate>
        <ServicePageBanner />
        <ServicePagesComponent content={content} />
        <ServicesSection
          selectedServices={[
            "Janam Kundali Chart",
            "Family Consultation",
            "Career Astrology",
            "Marriage Astrology",
          ]}
          serviceheading={"other Services"}
        />
        <FooterSection />
      </MainPageTemplate>
    </HelmetProvider>
  );
};

export default CorporateAstrology;
