import React from "react";
import MainPageTemplate from "../element/MainPageTemplate";
import ServicePageBanner from "../component/ServicePageBanner";
import ServicePagesComponent from "../component/ServicePagesComponent";
import ServicesSection from "../component/ServicesSection";
import FooterSection from "../component/FooterSection";
import { Helmet, HelmetProvider } from "react-helmet-async";

const MarriageAstrology = () => {
  const content = {
    title1: "Marriage Astrology:",
    description1:
      "Astrology can help to find a suitable partner and provide guidance for a happy married life. Nowadays many individuals seek the help of astrologers to know about their possible partners and get the right options. In Vedic Astrology, marriage is considered sacred and by matching horoscopes, the possibility of finding the right partner increases. Similarly, for married individuals, it can predict their future path and resolve conflicts so that they can lead a happy life. The sustainability of a marriage depends on the mutual understanding between the two individuals but through astrology, they can be shown the right path. It can predict which planets are creating conflicts or which Dasha is affecting the married life. For unmarried individuals, it can help them to know the delay in marriage or finding a suitable partner. Astrology can help people to find a soulmate to protect them from the effect of inauspicious planets in their Kundli.",
    title2: "Marriage Astrology:",
    description2:
      "People face different kinds of Dasha like Manglik dosh due to which they face a delay in marriage. Through marriage astrology, they can get the best remedy to fix such problems. Resolve issues in married life and get the help of the auspicious planets to fix marriage-related issues and get the right predictions for compatibility problems.",
  };
  return (
    <HelmetProvider>
      <Helmet>
        <title>Marriage Astrology</title>
        <meta
          name="description"
          content="Welcome to Aradhya Gems by Dr. Sridev Shastri, Best Astrologer in Howrah. A President Awardee Astrologer.
"
        />
      </Helmet>
      <MainPageTemplate>
        <ServicePageBanner />
        <ServicePagesComponent content={content} />
        <ServicesSection
          selectedServices={[
            "Janam Kundali Chart",
            "Family Consultation",
            "Career Astrology",
            "Marriage Astrology",
          ]}
          serviceheading={"other Services"}
        />
        <FooterSection />
      </MainPageTemplate>
    </HelmetProvider>
  );
};

export default MarriageAstrology;
