import React, { useState } from "react";
import MainPageTemplate from "../element/MainPageTemplate";
import ReactPlayer from "react-player";
import { FaTimes } from "react-icons/fa";
import AboutSection from "../component/AboutSection";

import { Helmet, HelmetProvider } from "react-helmet-async";

import FooterSection from "../component/FooterSection";

const AboutPage = () => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <HelmetProvider>
      <Helmet>
        <title>
          About- Best Astrologer in Howrah - Dr. Sridev Shastri | President
          Awardee Astrologer
        </title>
        <meta
          name="description"
          content="Welcome to Aradhya Gems by Dr. Sridev Shastri, Best Astrologer in Howrah. A President Awardee Astrologer."
        />
      </Helmet>
      <MainPageTemplate>
        <AboutSection />
        <div className="lg:p-16 sm:p-4 md:p-8">
          <div className="flex lg:flex-row-reverse sm:flex-col gap-4 items-center ">
            <div className="lg:w-[50%] sm:w-full flex items-center justify-center relative ">
              <div
                onClick={() => openModal()}
                className="bg-gray-800 w-[90%] h-fit rounded-lg cursor-pointer relative lg:top-4"
              >
                <img
                  src="images/award3.webp"
                  alt=""
                  className="w-full sm:h-[15rem] lg:h-[27rem] md:h-auto rounded-lg  opacity-70  "
                />
              </div>
              <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <img
                  onClick={() => openModal()}
                  src="images/play1.svg"
                  alt=""
                  className="w-[6rem] h-[6rem] cursor-pointer "
                />
              </span>
            </div>

            {showModal && (
              <div className="fixed top-0 left-0 w-full h-full z-[100] flex justify-center items-center bg-black bg-opacity-80">
                <div className="relative sm:w-full md:w-[90%] lg:w-[75%] sm:h-[18rem] md:h-[30rem] lg:h-[40rem] flex justify-center items-center">
                  <ReactPlayer
                    url="https://youtu.be/tDJcq9nDC5s?si=lf7lbVynQExiagrC"
                    width="100%"
                    height="100%"
                    controls={true}
                    playing={true}
                  />
                </div>
                <div className="top-0 absolute right-0 p-16">
                  <FaTimes
                    className="text-white text-3xl cursor-pointer"
                    onClick={closeModal}
                  />
                </div>
              </div>
            )}
            <div className="flex flex-col gap-6 lg:w-[50%] sm:w-full">
              <div className="flex flex-col gap-2">
                <h2 className="md:text-lg leading-8 sm:text-base">
                  Astrology is an ancient science that has proved that planetary
                  positions have a huge impact on our lives. To make it less
                  complicated, individuals consult with a genuine astrologer.
                  Dr. Sridev Shastri has been rewarded with the International
                  Business Leadership Award, International Glory Award, Best
                  Astrologer for celebrities, and much more. Along with these,
                  he has received an award from the ex-president of India Sri
                  Ramnath Kovind. His research papers and books are read by
                  people all around the world. Even top celebrities like Madhuri
                  Dixit, and Shilpa Shetty have rewarded Dr. Sridev Shastri for
                  his contribution to the astro science field.
                </h2>

                <h2 className="md:text-lg sm:text-base">
                  Over the past few years, people in Kolkata and all over the
                  world have gained benefits from his solutions. You can get
                  easy solutions through online and offline consultations. For
                  this, you have to fill out the online form and submit it. To
                  book offline and online consultations, 50% booking amount is
                  mandatory. So, seek consultation from renowned astrologer Dr.
                  Sridev Shastri and take solutions for your Career or
                  Business-related issues. Reach out to the best astrologer in
                  India by booking an appointment on these numbers - +91
                  9051822756 / 9051038837.
                </h2>
                <div className="md:text-lg sm:text-base">
                  Also reach out to Aradhya Gems for services related to Janam
                  Kundli, Match Making, Personal and Professional life
                  consultation. They also provide online solutions for family
                  and health-related problems.
                </div>
              </div>
            </div>
          </div>
        </div>

        <FooterSection />
      </MainPageTemplate>
    </HelmetProvider>
  );
};

export default AboutPage;
