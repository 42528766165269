import React from "react";
import MainPageTemplate from "../element/MainPageTemplate";
import ServicePageBanner from "../component/ServicePageBanner";
import BlogSection from "../component/BlogSection";
import FooterSection from "../component/FooterSection";
import { Helmet, HelmetProvider } from "react-helmet-async";

const BlogPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Our Daily Blogs </title>
        <meta
          name="description"
          content="Welcome to Aradhya Gems by Dr. Sridev Shastri, Best Astrologer in Howrah. A President Awardee Astrologer.
"
        />
      </Helmet>
      <MainPageTemplate>
        <ServicePageBanner />
        <BlogSection showsection={false} />
        <FooterSection />
      </MainPageTemplate>
    </HelmetProvider>
  );
};

export default BlogPage;
