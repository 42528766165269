import React from "react";
import Slider from "react-slick";

import HeadingComponent from "./HeadingComponent";
const TestimonialSection = () => {
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    arrows: false,
    centerMode: false,
  };
  const testimonials = [
    {
      review:
        "Dr. Sridev Shastri is simply the best astrologer I have ever come across. His predictions are not only accurate but also incredibly insightful. He has a deep understanding of astrology and a unique ability to explain complex concepts in a way that is easy to understand. What I appreciate most about him is his honesty and integrity. He genuinely cares about his clients and goes above and beyond to help them. Thanks to Dr. Sridev Shastri, I have been able to navigate through some challenging times with confidence and clarity. I highly recommend him to anyone seeking guidance in their life.",
      name: " Tanish Agarwal",
      city: "Client",
    },
    {
      review:
        "Really honoured and lucky to have met Dr.Sridev. He has achieved so many NATIONAL LEVEL AWARDS for his work in Astrology, but is still so humble and friendly. One of the best experience I had with an astrologer and definitely his suggestions and remedies helps in wonderful ways. RECOMMEND everyone with a belief in astrology to visit him once.",
      name: "Pradip Samanta",
      city: "Client",
    },
    {
      review:
        "one of the best astrologer in India.  I took online consultation with him... His prediction was so accurate.  Due to my financial issue i am unable to take remedies as of now but he is a great astrologer i must say..",
      name: "Sukanta Manujder",
      city: "Client",
    },
    {
      review:
        "Guruji is just awsome. He is a blessing I disguise for me. What ever prediction he has done is correct. He is not like others who want to mint money from you. You may have 100 question's he will patiently listen to you. I would recomend him. His charges a meger 900 inr. Others charge 2500. Even you can call him after your work is done. Thank you guruji for all you have done. Koti koti pranam.",
      name: "Arindam Roy",
      city: "Client",
    },
    {
      review:
        "The Cold Equipment from Shahil Traders is a lifesaver for my business. It keeps my ingredients fresh and my customers happy with the quality of my dishes.",
      name: "Abhijit Kar",
      city: "Client",
    },
  ];
  return (
    <div className=" ">
      <div className="lg:p-16 sm:p-4 md:p-8 flex flex-col gap-8">
        <HeadingComponent heading={"Whats Our Client Says"} />
        <div className=" w-full flex items-center justify-center  gap-8 ">
          <div className="lg:w-[90%] sm:w-full ml-8 ">
            <Slider {...settings}>
              {testimonials.map((testimonial, index) => (
                <div className="" key={index}>
                  <div className=" sm:w-[95%] sm:h-full lg:h-fit gap-4 p-8 text-center shadow-lg  items-center justify-center flex flex-col">
                    <div>
                      <img
                        src="images/testimonial1.svg"
                        alt=""
                        className="w-16 h-16"
                      />
                    </div>
                    <div className="text-base">{testimonial.review}</div>
                    <div>
                      <img
                        src="images/testimonial2.svg"
                        alt=""
                        className="w-10 h-10"
                      />
                    </div>
                    <div className="flex gap-4">
                      <div className="text-xl font-bold text-red-600">
                        {testimonial.name} ,
                      </div>
                      <div className="text-lg">{testimonial.city}</div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSection;
