import React, { useState } from "react";
import {
  FaInstagram,
  FaLinkedinIn,
  FaMinus,
  FaPhoneAlt,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
import { FaPlus, FaXTwitter } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { MdOutgoingMail, MdOutlinePayment } from "react-icons/md";

import { Link } from "react-router-dom";

const FooterSection = () => {
  const [showQuickLinks, setShowQuickLinks] = useState(false);
  const [showservicesLinks, setShowservicesLinks] = useState(false);
  const [showchambersLinks, setchambersLinks] = useState(false);
  const [showmediasLinks, setShowmediasLinks] = useState(false);

  const toggleQuickLinks = () => {
    setShowQuickLinks(!showQuickLinks);
  };
  const toggleservicesLinks = () => {
    setShowservicesLinks(!showservicesLinks);
  };
  const togglechambersLinks = () => {
    setchambersLinks(!showchambersLinks);
  };
  const togglemediasLinks = () => {
    setShowmediasLinks(!showmediasLinks);
  };
  const quickLinks = [
    { name: "Home", link: "/" },
    { name: "About", link: "/about-us" },
    { name: "gemstone", link: "/" },
    { name: "Free Calculator", link: "/" },
    { name: "Blog", link: "/blog" },

    { name: "Contact Us", link: "/contact-us" },
  ];
  const services = [
    { name: "Janam Kundali Chart", link: "/janam-kundali-chart" },
    { name: "Couple Match Making", link: "/couple-match-making" },
    { name: "Online Consultation", link: "/online-consultation" },
    { name: "Family Consultation", link: "/family-consultation" },
    { name: "Corporate Astrology", link: "/corporate-astrology" },
    { name: "Career Astrology", link: "/career-astrology" },
    { name: "Marriage Astrology", link: "/marriage-astrology" },
    { name: "Health Astrology", link: "/health-astrology" },
  ];
  const chambers = [
    { name: "Astrologer in Hooghly", link: "/astrologer-in-hooghly" },
    { name: "Astrologer in Howrah ", link: "/astrologer-in-howrah" },
    { name: "Astrologer in India ", link: "/astrologer-in-india" },
  ];
  const medias = [
    { name: " Achievements", link: "/awards-and-achievements" },
    { name: "Press Release", link: "/press-release" },
    { name: "Photo Gallery ", link: "/photo-gallery" },
    { name: "Video Gallery ", link: "/video-gallery" },
  ];
  return (
    <div className="bg-black">
      <div className="lg:p-16 sm:p-4 md:p-8 h-full ">
        <div className=" flex-col flex items-center justify-center border-b-2 border-yellow-400 pb-8">
          <div className="grid lg:grid-cols-3 sm:grid-cols-1 sm:gap-8 lg:gap-16 ">
            <div className="md:w-[85%] sm:w-full  h-fit rounded-lg shadow-xl text-yellow-400  flex flex-col gap-6">
              <div>
                <img
                  src="images/footerlogo.png"
                  alt=""
                  className="w-[10rem] h-auto"
                />
              </div>
              <div className="flex gap-2 md:text-xl sm:text-sm items-start">
                <span>
                  <FaLocationDot />
                </span>
                <span>
                  10/2A, Tusti Apartment, Nepal Bhattacharjee Street, Anami
                  Sangha, Kalighat, Kolkata, West Bengal 700026
                </span>
              </div>
              <div className="flex gap-2 items-center md:text-xl sm:text-sm">
                <span>
                  <MdOutgoingMail />
                </span>
                <Link to="mailto:astrologersridev@gmail.com">
                  astrologersridev@gmail.com
                </Link>
              </div>
              <div className=" flex gap-2 items-center md:text-xl sm:text-sm">
                <span>
                  <FaPhoneAlt />
                </span>

                <Link to="tel:9051822756">+91 9051822756 </Link>
              </div>
              <div className=" flex gap-2 items-center md:text-xl sm:text-sm">
                <span>
                  <FaPhoneAlt />
                </span>

                <Link to="tel:7980787603">+91 7980787603</Link>
              </div>
            </div>
            <div className="flex sm:flex-col lg:flex-row sm:gap-8 lg:gap-0 lg:justify-between">
              <div className="flex flex-col  gap-6 text-yellow-400">
                <div
                  onClick={toggleQuickLinks}
                  className="flex justify-between"
                >
                  <span className="text-3xl font-bold">Quick Links</span>
                  <span className="border-2 border-white h-8 w-8 flex justify-center items-center lg:hidden ">
                    {showQuickLinks ? <FaMinus /> : <FaPlus />}
                  </span>
                </div>
                <div className="lg:flex sm:hidden  flex-col gap-4 sm:text-lg md:text-xl">
                  {quickLinks.map((quicklink, index) => (
                    <Link
                      to={quicklink.link}
                      className="flex flex-row gap-2 items-center"
                      key={index}
                    >
                      <span></span>
                      <span>{quicklink.name}</span>
                    </Link>
                  ))}
                </div>
                {showQuickLinks && (
                  <div className="flex flex-col gap-2 lg:hidden sm:text-lg md:text-xl">
                    {quickLinks.map((quicklink, index) => (
                      <span
                        className="flex flex-row gap-2 items-center"
                        key={index}
                      >
                        <span></span>
                        <span>{quicklink.name}</span>
                      </span>
                    ))}
                  </div>
                )}
              </div>

              <div className="flex flex-col gap-6 text-yellow-400">
                <div
                  onClick={toggleservicesLinks}
                  className="flex justify-between"
                >
                  <span className="text-3xl font-bold">Our Services</span>
                  <span className="border-2 border-white h-8 w-8 flex justify-center items-center lg:hidden ">
                    {showservicesLinks ? <FaMinus /> : <FaPlus />}
                  </span>
                </div>

                <div className="lg:flex sm:hidden flex-col gap-4 sm:text-lg md:text-xl">
                  {services.map((service, index) => (
                    <Link
                      to={service.link}
                      className="flex flex-row gap-2 items-center"
                      key={index}
                    >
                      <span></span>
                      <span>{service.name}</span>
                    </Link>
                  ))}
                </div>
                {showservicesLinks && (
                  <div className="flex lg:hidden flex-col gap-2 sm:text-lg md:text-xl">
                    {services.map((service, index) => (
                      <Link
                        to={service.link}
                        className="flex flex-row gap-2 items-center"
                        key={index}
                      >
                        <span></span>
                        <span>{service.name}</span>
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="flex sm:flex-col lg:flex-row sm:gap-8 lg:gap-0 lg:justify-between">
              <div className="flex flex-col gap-6 text-yellow-400">
                <div
                  onClick={togglemediasLinks}
                  className="flex justify-between"
                >
                  <span className="text-3xl font-bold">Media</span>
                  <span className="border-2 border-white h-8 w-8 flex justify-center items-center lg:hidden ">
                    {showmediasLinks ? <FaMinus /> : <FaPlus />}
                  </span>
                </div>

                <div className="lg:flex sm:hidden flex-col gap-4 sm:text-lg md:text-xl">
                  {medias.map((media, index) => (
                    <Link
                      to={media.link}
                      className="flex flex-row gap-2 items-center"
                      key={index}
                    >
                      <span></span>
                      <span>{media.name}</span>
                    </Link>
                  ))}
                </div>
                {showmediasLinks && (
                  <div className="flex lg:hidden flex-col gap-2 sm:text-lg md:text-xl">
                    {medias.map((media, index) => (
                      <Link
                        to={media.link}
                        className="flex flex-row gap-2 items-center"
                        key={index}
                      >
                        <span></span>
                        <span>{media.name}</span>
                      </Link>
                    ))}
                  </div>
                )}
              </div>
              <div className="flex flex-col  gap-6 text-yellow-400">
                <div
                  onClick={togglechambersLinks}
                  className="flex justify-between"
                >
                  <span className="text-3xl font-bold">Chambers</span>
                  <span className="border-2 border-white h-8 w-8 flex justify-center items-center lg:hidden ">
                    {showchambersLinks ? <FaMinus /> : <FaPlus />}
                  </span>
                </div>
                <div className="lg:flex sm:hidden  flex-col gap-4 sm:text-lg md:text-xl">
                  {chambers.map((chamber, index) => (
                    <Link
                      to={chamber.link}
                      className="flex flex-row gap-2 items-center"
                      key={index}
                    >
                      <span></span>
                      <span>{chamber.name}</span>
                    </Link>
                  ))}
                </div>
                {showchambersLinks && (
                  <div className="flex flex-col gap-2 lg:hidden sm:text-lg md:text-xl">
                    {chambers.map((chamber, index) => (
                      <Link
                        to={chamber.link}
                        className="flex flex-row gap-2 items-center"
                        key={index}
                      >
                        <span></span>
                        <span>{chamber.name}</span>
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full lg:p-8 sm:p-2 md:p-4 h-full ">
          <div className="flex lg:flex-row sm:gap-6 lg:gap-0 sm:flex-col justify-evenly items-center text-xl">
            <iframe
              src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fastrologersridev%3Fmibextid%3DZbWKwL&tabs&width=340&height=130&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
              width="340"
              height="130"
              loading="lazy"
              title="fb page plugin"
            ></iframe>
            <div className=""></div>
            <Link
              to="https://payu.in/pay/7094D4D6A58E5D17B27701CBEAA6FE81 "
              className="md:flex sm:hidden w-[14rem] h-[3rem] p-2 justify-between items-center border text-xl font-semibold text-yellow-400 border-yellow-400 rounded-lg"
            >
              <span>Pay Now</span>
              <span>
                <MdOutlinePayment />
              </span>
            </Link>
            <div className="flex flex-col justify-center gap-2 items-center">
              <span className="text-yellow-400">We Are Available</span>
              <div className="flex gap-4 text-xl">
                <Link
                  to="https://api.whatsapp.com/send?phone=7980787603"
                  className="h-8 w-8 flex items-center justify-center rounded-full bg-yellow-400"
                >
                  <FaWhatsapp />
                </Link>
                <Link
                  to="https://www.youtube.com/@drsridev"
                  className="h-8 w-8 flex items-center justify-center rounded-full bg-yellow-400"
                >
                  <FaYoutube />
                </Link>
                <Link
                  to={
                    "https://www.instagram.com/drsridev?igsh=MTlpanh4cGFvejBydw=="
                  }
                  className="h-8 w-8 flex items-center justify-center rounded-full bg-yellow-400"
                >
                  <FaInstagram />
                </Link>
                <Link
                  to={
                    "https://in.linkedin.com/in/dr-sridev-shastri-best-astrologer-in-kolkata-6469111ab"
                  }
                  className="h-8 w-8 flex items-center justify-center rounded-full bg-yellow-400"
                >
                  <FaLinkedinIn />
                </Link>
                <Link
                  to={"https://twitter.com/shastrisridev"}
                  className="h-8 w-8 flex items-center justify-center rounded-full bg-yellow-400"
                >
                  <FaXTwitter />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex md:flex-row md:h-fit sm:h-[9rem] sm:flex-col sm:text-xs md:text-lg lg:text-xl sm:justify-start items-center md:justify-between bg-black border-t border-yellow-400  sm:p-2 md:p-6 ">
        <div className="  text-yellow-400    flex   ">
          Copyright © Dr. Sridev Shastri 2012-24
        </div>
        <div className="  text-yellow-400      flex gap-2  ">
          <span>Developed By:</span>
          <Link to="https://rebootmarketing.in/">
            Reboot Marketing Pvt. Ltd.
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FooterSection;
