import React from "react";
import MainPageTemplate from "../element/MainPageTemplate";
import ServicePagesComponent from "../component/ServicePagesComponent";
import ServicePageBanner from "../component/ServicePageBanner";

import FooterSection from "../component/FooterSection";
import { Helmet, HelmetProvider } from "react-helmet-async";

const AstrologerInIndia = () => {
  const content = {
    title1: "Best Astrologer in India",
    description1:
      "WAstrology is an ancient science that interprets the position of celestial bodies and how they will affect human affairs. Astrologers use this science to provide information related to relationships, finances, careers, personality, and different life events. When it comes to the best Astrologer in India then Dr. Sridev Shastri is the ideal person.",
    title2: "Experience in Astrology and Customer Experience",
    description2:
      "He has more than 30 years of experience in Vedic Astrology. With over 50,000+ satisfied clients, he is an expert in Astro science. He offers personalized training and is an international researcher. His research papers and books are read by people all around the world. He holds a PhD in Astrology and is invited as a guest speaker at the Top universities in Singapore and the US.",
    description3:
      "He has been ranked first on Google as well as ranked as the best astrologer in India on websites like Justdial as well.",
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>
          Best Astrologer in India - Dr. Sridev Shastri | President Awardee
          Astrologer
        </title>
        <meta
          name="description"
          content="Welcome to Aradhya Gems by Dr. Sridev Shastri, Best Astrologer in Howrah. A President Awardee Astrologer.
"
        />
      </Helmet>
      <MainPageTemplate>
        <ServicePageBanner />
        <ServicePagesComponent content={content} />
        <div className="lg:p-16 md:p-8 sm:p-4">
          <div className="flex justify-center flex-col gap-6 items-center md:text-xl sm:text-lg lg:text-2xl">
            <span className=" text-red-600  font-bold">
              Services Offered by Dr. Shastri
            </span>
            <div className="flex flex-col gap-4">
              <span className="text-red-600  font-bold">
                Marriage Consultation:
              </span>
              <span className="text-lg">
                In Kundli, the 7th house is the house of Astrology. If you are
                facing obstacles in married life or not getting married due to
                the ill effects of the planets then consult Dr. Shastri as he is
                a marriage expert.
              </span>
            </div>
            <div className="flex flex-col gap-4">
              <span className="text-red-600  font-bold">
                {" "}
                Love Consultation:
              </span>
              <span className="text-lg">
                Planets have a huge impact on the love life. The placement of
                the Moon is considered auspicious in our Kundli. Dr. Shastri
                provides 100% true predictions to solve love issues. If you are
                facing problems to approach or get success in your love life
                then contact him to get the appropriate solutions.
              </span>
            </div>{" "}
            <div className="flex flex-col gap-4">
              <span className="text-red-600  font-bold">
                Career Consultation:
              </span>
              <span className="text-lg">
                A successful career shapes our future and makes us confident in
                life. Every house has a different effect on our career. Dr.
                Sridev Shastri provides career consultation and is the best
                career counsellor.
              </span>
            </div>
            <div className="flex flex-col gap-4">
              <span className="text-red-600  font-bold">
                Awards and Recognition
              </span>
              <span className="text-lg">
                Dr. Shastri has been awarded the Dr. B.R Ambedkar Bharat Ratna
                Award by the Ex-President of India Sri. Ramnath Kovind.
              </span>
              <span className="text-lg">
                Best Celebrity Astrologer by Madhuri Dixit.
              </span>
              <span className="text-lg">
                Lokshahi Sonman 2023 by Chief Minister of Maharashtra Eknath
                Shinde.
              </span>
              <span className="text-lg">
                Many more awards are awarded to him as the best astrologer in
                India for his contribution to Astrology and he has gained
                National and International fame due to his work.
              </span>
            </div>
            <div className="flex flex-col gap-4">
              <span className="text-red-600  font-bold">
                {" "}
                How to Book an Appointment?
              </span>
              <span className="text-lg">
                Dr. Shastri offers Online and Offline Consultation. For Online
                Consultation, call these numbers and book an appointment -
                919051822756 / +91 9051038837. You can also fill out the online
                form and book. For Offline, he can be reached out to his
                physical chambers. For booking, a 50% advanced amount is
                mandatory.
              </span>
            </div>
          </div>
        </div>
        <FooterSection />
      </MainPageTemplate>
    </HelmetProvider>
  );
};

export default AstrologerInIndia;
