import React from "react";
import MainPageTemplate from "../element/MainPageTemplate";
import ServicePageBanner from "../component/ServicePageBanner";
import ServicePagesComponent from "../component/ServicePagesComponent";
import ServicesSection from "../component/ServicesSection";
import FooterSection from "../component/FooterSection";
import { Helmet, HelmetProvider } from "react-helmet-async";

const HealthAstrology = () => {
  const content = {
    title1: "Health Astrology:",
    description1:
      "Health Astrology can assess diseases and provide the strength to prevent them at the right time. People suffer from life-threatening diseases and have to depend on medicines for survival. Even after getting the right treatment, they fall ill easily. The answer to these questions can be found through health astrology. Health astrology can cure medical-related issues. Health astrology can study the health-related issues of an individual and can predict the disease the native is suffering from or will suffer in the future. ",
    title2: "Health Astrology:",
    description2:
      "Planets and Nakshatras have some hidden meaning about our health. By analyzing the strengths and weaknesses of these, astrologers can help to provide solutions or natural remedies. In our birth chart, the 6th house is the house of disease that affects our health. By knowing the planetary positions and their effects health issues can be resolved. People who suffer from major diseases can be provided with remedies to cure the disease. Each zodiac sign or planets affect the different parts of the human body. To know how they are going to affect precautions can be taken. Health astrology can predict serious diseases and suggest healing solutions. Therefore, it is important to get the help of an expert astrologer and find the best possible solution for ourselves or our closed ones.",
  };
  return (
    <HelmetProvider>
      <Helmet>
        <title>Health Astrology</title>
        <meta
          name="description"
          content="Welcome to Aradhya Gems by Dr. Sridev Shastri, Best Astrologer in Howrah. A President Awardee Astrologer.
"
        />
      </Helmet>
      <MainPageTemplate>
        <ServicePageBanner />
        <ServicePagesComponent content={content} />
        <ServicesSection
          selectedServices={[
            "Janam Kundali Chart",
            "Family Consultation",
            "Career Astrology",
            "Marriage Astrology",
          ]}
          serviceheading={"other Services"}
        />
        <FooterSection />
      </MainPageTemplate>
    </HelmetProvider>
  );
};

export default HealthAstrology;
