import React from "react";
import MainPageTemplate from "../element/MainPageTemplate";
import { Link } from "react-router-dom";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutgoingMail } from "react-icons/md";
import { SlLocationPin } from "react-icons/sl";
import FooterSection from "../component/FooterSection";
import { Helmet, HelmetProvider } from "react-helmet-async";

import EnquirySection from "../component/EnquirySection";
import HeadingComponent from "../component/HeadingComponent";
import ServicePageBanner from "../component/ServicePageBanner";

const ContactUs = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>
          Contact With Best Astrologer in Howrah - Dr. Sridev Shastri |
          President Awardee Astrologer
        </title>
        <meta
          name="description"
          content="Welcome to Aradhya Gems by Dr. Sridev Shastri, Best Astrologer in Howrah. A President Awardee Astrologer.
"
        />
      </Helmet>
      <MainPageTemplate>
        <ServicePageBanner />
        <div className="lg:p-16 md:p-8 sm:p-4 flex flex-col justify-center sm:gap-5 lg:gap-10 items-center">
          <HeadingComponent heading=" Adreess Our Chambers" />
          <div className="flex">
            <div className="w-full flex flex-col gap-6">
              <div className="flex md:flex-row sm:flex-col justify-center sm:gap-5 lg:gap-10 items-center">
                <div className="flex sm:w-full lg:w-[40%] rounded-lg lg:text-xl  md:text-lg sm:text-sm flex-col  p-4 border-2 border-gray-300 gap-6">
                  <span className="text-center font-bold underline">
                    Kolkata Chamber
                  </span>
                  <div className="flex gap-2  items-start">
                    <span>
                      <FaLocationDot />
                    </span>
                    <span className="flex flex-wrap gap-1 h-[5rem]">
                      <span>10/2A, Tusti Apartment,</span>{" "}
                      <span> Nepal Bhattacharjee Street, Anami Sangha,</span>
                      <span> Kalighat, Kolkata, </span>
                      <span>West Bengal 700026</span>
                    </span>
                  </div>
                  <div className="flex gap-2 items-center">
                    <span>
                      <MdOutgoingMail />
                    </span>
                    <span>astrologersridev@gmail.com</span>
                  </div>
                  <div className="flex gap-2 items-center ">
                    <span>
                      <FaPhoneAlt />
                    </span>
                    <span>+91 9051822756 / +91 7980787603</span>
                  </div>
                  <div className="flex gap-2 items-center border-t-2 pt-2 text-xl">
                    <Link
                      target="_Blank"
                      to="https://maps.app.goo.gl/ozdkh5GMcvHtZYTB9"
                      className="w-[10rem] p-2 rounded-md items-center text-lg flex justify-between h-fit bg-red-700 text-yellow-400"
                    >
                      <span>
                        <SlLocationPin />
                      </span>
                      <span>Get Direction</span>
                    </Link>
                  </div>
                </div>
                <div className="flex sm:w-full lg:w-[40%] lg:text-xl  md:text-lg sm:text-sm rounded-lg flex-col  p-4 border-2 border-gray-300 gap-6">
                  <span className="text-center font-bold underline">
                    Hooghly Chamber
                  </span>
                  <div className="flex gap-2  items-start">
                    <span>
                      <FaLocationDot />
                    </span>
                    <span className="flex flex-wrap gap-1 h-[5rem]">
                      <span>Room no 140, Super market, railway station,</span>
                      <span>Serampore, Gopinath Saha St,</span>
                      <span>near Serampur, Serampore, West Bengal 712201</span>
                    </span>
                  </div>
                  <div className="flex gap-2 items-center ">
                    <span>
                      <MdOutgoingMail />
                    </span>
                    <span>astrologersridev@gmail.com</span>
                  </div>
                  <div className="flex gap-2 items-center ">
                    <span>
                      <FaPhoneAlt />
                    </span>
                    <span>+91 9051822756 / +917980787603</span>
                  </div>
                  <div className="flex gap-2 items-center border-t-2 pt-2 text-xl">
                    <Link
                      target="_Blank"
                      to="https://maps.app.goo.gl/az27MejZaQrvQdzs5"
                      className="w-[10rem] p-2 rounded-md items-center text-lg flex justify-between h-fit bg-red-700 text-yellow-400"
                    >
                      <span>
                        <SlLocationPin />
                      </span>
                      <span>Get Direction</span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="flex md:flex-row sm:flex-col justify-center sm:gap-5 lg:gap-10 items-center">
                <div className="flex sm:w-full lg:w-[40%] rounded-lg lg:text-xl  md:text-lg sm:text-sm flex-col  p-4 border-2 border-gray-300 gap-6">
                  <span className="text-center font-bold underline">
                    Howrah Chamber
                  </span>
                  <div className="flex gap-2  items-start">
                    <span>
                      <FaLocationDot />
                    </span>
                    <span className="flex flex-wrap gap-1 h-[5rem]">
                      <span>Sarat Sadan, Nabadisha 5 No, </span>
                      <span>Mahatma Gandhi Rd, opposite Howrah, </span>
                      <span>West Bengal 711101</span>
                    </span>
                  </div>
                  <div className="flex gap-2 items-center">
                    <span>
                      <MdOutgoingMail />
                    </span>
                    <span>astrologersridev@gmail.com</span>
                  </div>
                  <div className="flex gap-2 items-center ">
                    <span>
                      <FaPhoneAlt />
                    </span>
                    <span>+91 9051822756 / +917980787603</span>
                  </div>
                  <div className="flex gap-2 items-center border-t-2 pt-2 text-xl">
                    <Link
                      target="_Blank"
                      to="https://maps.app.goo.gl/BwZRqkCttuFD3pjz6"
                      className="w-[10rem] p-2 rounded-md items-center text-lg flex justify-between h-fit bg-red-700 text-yellow-400"
                    >
                      <span>
                        <SlLocationPin />
                      </span>
                      <span>Get Direction</span>
                    </Link>
                  </div>
                </div>
                <div className="flex sm:w-full lg:w-[40%] rounded-lg lg:text-xl  md:text-lg sm:text-sm flex-col  p-4 border-2 border-gray-300 gap-6">
                  <span className="text-center font-bold underline">
                    Mumbai Chamber
                  </span>
                  <div className="flex gap-2  items-start">
                    <span>
                      <FaLocationDot />
                    </span>
                    <span className="flex flex-wrap gap-1 h-[5rem]">
                      <span>Sassion Dock, Colaba, </span>
                      <span> Mumbai, </span>
                      <span>Maharashtra 400005</span>
                    </span>
                  </div>
                  <div className="flex gap-2 items-center">
                    <span>
                      <MdOutgoingMail />
                    </span>
                    <span>astrologersridev@gmail.com</span>
                  </div>
                  <div className="flex gap-2 items-center ">
                    <span>
                      <FaPhoneAlt />
                    </span>
                    <span>+91 9051822756 / +917980787603</span>
                  </div>
                  <div className="flex gap-2 items-center border-t-2 pt-2 text-xl">
                    <Link
                      target="_Blank"
                      to="https://maps.app.goo.gl/BwZRqkCttuFD3pjz6"
                      className="w-[10rem] p-2 rounded-md items-center text-lg flex justify-between h-fit bg-red-700 text-yellow-400"
                    >
                      <span>
                        <SlLocationPin />
                      </span>
                      <span>Get Direction</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex sm:flex-col md:flex-row sm:p-4 md:p-8 lg:p-16 sm:gap-5 lg:gap-10 justify-center items-center">
          <div className="lg:w-[35%] sm:w-full flex text-red-650 text-lg flex-col gap-2">
            <EnquirySection />
          </div>

          <div className="lg:w-[55%] sm:w-full h-full  flex justify-center items-center">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3685.633987979589!2d88.33885061087771!3d22.517911679445316!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0277af6ef183e3%3A0xb1217b983abe8466!2sDr.%20Sridev%20Shastri%20best%20astrologer%20in%20Kolkata!5e0!3m2!1sen!2sin!4v1712427780634!5m2!1sen!2sin"
              loading="lazy"
              className="w-full h-[30rem]  rounded-lg"
              title="map"
            ></iframe>
          </div>
        </div>
        <FooterSection />
      </MainPageTemplate>
    </HelmetProvider>
  );
};

export default ContactUs;
