import React from "react";
import MainPageTemplate from "../element/MainPageTemplate";

import FooterSection from "../component/FooterSection";
import ServicesSection from "../component/ServicesSection";
import ServicePagesComponent from "../component/ServicePagesComponent";
import ServicePageBanner from "../component/ServicePageBanner";
import { Helmet, HelmetProvider } from "react-helmet-async";

const JanamKundaliChart = () => {
  const content = {
    title1: "Janam Kundli Chart:",
    description1:
      "To predict the future in astrology, Janam Kundli's reading is important. It is the birth chart that helps to interpret the position of the planet precisely. Janam Kundli is the birth chart of an individual that shows how the motion of celestial bodies will affect their life. For this, birth time, birth date, and place of birth are important. It is also referred to as the Janam  Patrika. It provides insights about the major milestones in life.A birth chart has 12 rashis and how planets are placed in them.",
    title2: "Janam Kundli Chart:",
    description2:
      "A deeper analysis of the Janam Kundli Chart will give solutions for the negative energy due to the inauspicious planets. Kundli is not just about the minor details but rather it predicts a person’s career, love life, and other different events. Janam Kundli should be created by an expert as it contains intricate details about one’s life. It consists of 12 houses and each house is connected to relationships, finance, etc. These houses can affect the personality traits of an individual. To make your Kundli strong and to make it stronger, it is made with accuracy. Making a Janam Kundli is an ancient practice adopted in the Hindu culture.",
  };
  return (
    <HelmetProvider>
      <Helmet>
        <title>Janam Kundali Chart</title>
        <meta
          name="description"
          content="Welcome to Aradhya Gems by Dr. Sridev Shastri, Best Astrologer in Howrah. A President Awardee Astrologer."
        />
      </Helmet>
      <MainPageTemplate>
        <ServicePageBanner />
        <ServicePagesComponent content={content} />
        <ServicesSection
          selectedServices={[
            "Online Consultation",
            "Family Consultation",
            "Career Astrology",
            "Marriage Astrology",
          ]}
          serviceheading={"Other Services"}
        />
        <FooterSection />
      </MainPageTemplate>
    </HelmetProvider>
  );
};

export default JanamKundaliChart;
