import React from "react";
import MainPageTemplate from "../element/MainPageTemplate";
import ServicePagesComponent from "../component/ServicePagesComponent";
import ServicesSection from "../component/ServicesSection";
import FooterSection from "../component/FooterSection";
import ServicePageBanner from "../component/ServicePageBanner";
import { Helmet, HelmetProvider } from "react-helmet-async";

const CoupleMatchMaking = () => {
  const content = {
    title1: "Couple MatchMaking:",
    description1:
      " Marriage is one of the purest relations that requires mutual efforts but to make it compatible couple matchmaking plays a vital role in our lives. Before marriage, it is important to know about the compatibility between the couples so that they can lead a good life in the future. It is the secret to a harmonious married life. By doing couple matchmaking, a strong foundation can be built that can predict chances of success in marriage. By reading the birth chart of two individuals, their mutual understanding can be predicted.",
    title2: "Couple MatchMaking:",
    description2:
      "It is considered an ancient practice to fix a marriage. By analyzing this, the doshas can be fixed through possible solutions. It maximizes the chances of finding the true love or your soulmate. It is always better to know the potential harmful compatibility issues so that they can be fixed at the right time. It shows that two individuals are compatible in terms of love, career, and different aspects of life and can balance each other wisely. So, to lead a happy married life and a fulfilling relationship is accepted as an ancient practice in the Hindu tradition. Through this, proper guidance can be provided to the couples and their family.",
  };
  return (
    <HelmetProvider>
      <Helmet>
        <title>Couple Match Making</title>
        <meta
          name="description"
          content="Welcome to Aradhya Gems by Dr. Sridev Shastri, Best Astrologer in Howrah. A President Awardee Astrologer.
"
        />
      </Helmet>
      <MainPageTemplate>
        <ServicePageBanner />
        <ServicePagesComponent content={content} />
        <ServicesSection
          selectedServices={[
            "Online Consultation",
            "Family Consultation",
            "Career Astrology",
            "Marriage Astrology",
          ]}
          serviceheading={"other Services"}
        />
        <FooterSection />
      </MainPageTemplate>
    </HelmetProvider>
  );
};

export default CoupleMatchMaking;
