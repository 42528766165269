import React, { useState } from "react";

import ReactPlayer from "react-player/youtube";
import { FaTimes } from "react-icons/fa";

const AboutSection = () => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <div className="lg:p-16 sm:p-4 md:p-8">
      <div className="flex lg:flex-row sm:flex-col after: gap-4 items-center ">
        <div className="lg:w-[50%] sm:w-full flex items-center justify-center relative ">
          <div
            onClick={() => openModal()}
            className=" w-[90%] h-fit  cursor-pointer relative lg:top-4"
          >
            <img
              src="images/award4.jpg"
              alt=""
              className="w-full sm:h-[15rem] lg:h-[27rem] rounded-tl-xl rounded-br-xl md:h-auto   "
            />
          </div>
          <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <img
              onClick={() => openModal()}
              src="images/play1.svg"
              alt=""
              className="w-[6rem] h-[6rem] cursor-pointer "
            />
          </span>
        </div>

        {showModal && (
          <div className="fixed top-0 left-0 w-full h-full z-[100] flex justify-center items-center bg-black bg-opacity-80">
            <div className="relative sm:w-full md:w-[90%] lg:w-[75%] sm:h-[18rem] md:h-[30rem] lg:h-[40rem] flex justify-center items-center">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=65eAbIY7dAo&ab_channel=Dr.sridevshastri"
                width="100%"
                height="100%"
                controls={true}
                playing={true}
              />
            </div>
            <div className="top-0 absolute right-0 p-16">
              <FaTimes
                className="text-white text-3xl cursor-pointer"
                onClick={closeModal}
              />
            </div>
          </div>
        )}
        <div className="flex flex-col gap-6 lg:w-[50%] sm:w-full">
          <div className="flex flex-col gap-2">
            <h1 className="md:text-3xl lg:text-4xl sm:text-xl text-center font-semibold text-red-600">
              About Dr. Sridev Shastri
            </h1>
            <h2 className="md:text-lg leading-8 sm:text-base">
              Dr. Sridev Shastri is a PhD astro expert known for his
              contribution to astrology. With 30 years of experience, he is
              ranked first in the Google search. Born and brought up in Kolkata,
              he has solved astrology-related problems for people all around
              India and abroad. He provides astrological solutions to
              individuals facing problems related to love, marriage, career, and
              business. He believes that “If Vedic Yug Really Exists, Then
              Astrology is Real, & My prediction is 100% Genuine.” He gained his
              knowledge of Astrology when he resided in Tarapith in Kolkata
              during his early days.
            </h2>
          </div>
          <div className="flex flex-col gap-2">
            <h2 className="md:text-lg sm:text-base">
              With over 28K Globally Satisfied clientele, he has been awarded
              several National and International awards for his contribution to
              Vedic Astrology. Dr. Shastri has a good reputation among clients
              dealing with continuous difficulties. Apart from the above
              problems, he also deals in Kundli making, tantra puja, palmistry,
              gemology, and numerology. He is the most trusted astrologer in
              India who completed his Ph.D. in Astrology as a gold medallist. He
              is invited as a Guest speaker at various National and
              International events.
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
