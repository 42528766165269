import React from "react";
import MainPageTemplate from "../element/MainPageTemplate";
import ServicePageBanner from "../component/ServicePageBanner";
import ServicePagesComponent from "../component/ServicePagesComponent";
import ServicesSection from "../component/ServicesSection";
import FooterSection from "../component/FooterSection";
import { Helmet, HelmetProvider } from "react-helmet-async";

const OnlineConsultation = () => {
  const content = {
    title1: "Online Consultation:",
    description1:
      "Get personalized consultation on various issues with our Online Consultation services and get the best solution. We deal in marriage astrology, matchmaking, Kundli making, corporate astrology, and much more. With the increase in the success of astrology predictions, Online Consultation has a huge demand nowadays. Today, people can seek out a good astrologer through this and get an instant solution for their problems. Through this, you can talk to the best astrologer of your choice and get the answers to your problems.",
    title2: "Online Consultation:",
    description2:
      "Online Consultation is important because not everyone can reach the Physical chamber today due to their busy schedules. It saves time as well as provides immediate solutions. All you need is an expert in Astrology and a call. It is one of the easiest ways to predict the events in one’s life. We provide Online Consultation and can be reached out by filling out an online form too! It is just a clicky-away solution nowadays and provides the right guidance for people who are facing difficulties in their lives.",
  };
  return (
    <HelmetProvider>
      <Helmet>
        <title>Online Consultation</title>
        <meta
          name="description"
          content="Welcome to Aradhya Gems by Dr. Sridev Shastri, Best Astrologer in Howrah. A President Awardee Astrologer.
"
        />
      </Helmet>
      <MainPageTemplate>
        <ServicePageBanner />
        <ServicePagesComponent content={content} />
        <ServicesSection
          selectedServices={[
            "Janam Kundali Chart",
            "Family Consultation",
            "Career Astrology",
            "Marriage Astrology",
          ]}
          serviceheading={"other Services"}
        />
        <FooterSection />
      </MainPageTemplate>
    </HelmetProvider>
  );
};

export default OnlineConsultation;
