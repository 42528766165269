import React from "react";
import MainPageTemplate from "../element/MainPageTemplate";
import ServicePageBanner from "../component/ServicePageBanner";
import ServicePagesComponent from "../component/ServicePagesComponent";
import ServicesSection from "../component/ServicesSection";
import FooterSection from "../component/FooterSection";
import { Helmet, HelmetProvider } from "react-helmet-async";

const CareerAstrology = () => {
  const content = {
    title1: "Online Consultation:",
    description1:
      "Nowadays, getting a job is hard, and choosing the right career path is harder. There are different fields but what suits the best is quite difficult to predict. Career astrology can here help to provide guidance and suggest possible career options for an individual. Astrology can make a career journey much simpler. It is important to know what will be the right path to pursue in the future, which course to join, and the chances of success in that career field. Based on your birth chart, an astrologer can predict the risks and opportunities in various career options and identify the good and bad effects.",
    title2: "Online Consultation:",
    description2:
      "To shape a successful career, you must have a clear idea of the subjects. For people who are looking for a job or are finding it hard to get success in their current job, career astrology can be the right option. A zodiac sign can predict the career journey successfully. To know about the right career options, choose a career astrology service and prosper in any field of your choice. Therefore, career astrology can help to get the right career options and help in overcoming workplace challenges.",
  };
  return (
    <HelmetProvider>
      <Helmet>
        <title>Career Astrology</title>
        <meta
          name="description"
          content="Welcome to Aradhya Gems by Dr. Sridev Shastri, Best Astrologer in Howrah. A President Awardee Astrologer.
"
        />
      </Helmet>
      <MainPageTemplate>
        <ServicePageBanner />
        <ServicePagesComponent content={content} />
        <ServicesSection
          selectedServices={[
            "Janam Kundali Chart",
            "Family Consultation",
            "Career Astrology",
            "Marriage Astrology",
          ]}
          serviceheading={"other Services"}
        />
        <FooterSection />
      </MainPageTemplate>
    </HelmetProvider>
  );
};

export default CareerAstrology;
