import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Banner from "../component/Banner";
import emailjs from "@emailjs/browser";
import AboutSection from "../component/AboutSection";
import ServicesSection from "../component/ServicesSection";
import GallarySection from "../component/GallarySection";
import NewsFeaturedSection from "../component/NewsFeaturedSection";
import AwardSection from "../component/AwardSection";
import TestimonialSection from "../component/TestimonialSection";
import { FaLocationDot, FaXTwitter } from "react-icons/fa6";
import { MdOutgoingMail, MdOutlinePayment } from "react-icons/md";
import {
  FaInstagram,
  FaLinkedinIn,
  FaPhoneAlt,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
import { RiContractRightLine } from "react-icons/ri";
import { Helmet, HelmetProvider } from "react-helmet-async";

import OnlyMobile from "../element/OnlyMobile";
const AddPageBestAstrologer = () => {
  const equipments = [
    {
      icons: "images/counting1.svg",
      text: "President Awardee Astrologer in India",
    },
    {
      icons: "images/counting5.svg",
      text: "Renowned Vedic Astrologer near You",
    },
    {
      icons: "images/counting3.svg",
      text: "30+ Years Of Experience in Astrology",
    },
    {
      icons: "images/counting4.svg",
      text: "Quick Solution & Accurate Prediction",
    },
  ];
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, phone } = formData;

    if (!name || !phone) {
      alert("Please fill in all required fields.");
      return;
    }

    emailjs
      .sendForm(
        "service_qthbrhg",
        "template_xxxte42",
        e.target,
        "-ig0XAkykaMriOcqT"
      )
      .then(
        () => {
          navigate("/thank-you");
        },
        (error) => {
          console.log(error.text);
        }
      );

    setFormData({
      name: "",
      phone: "",
      message: "",
    });
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>
          Best Astrologer in Howrah - Dr. Sridev Shastri | President Awardee
          Astrologer
        </title>
        <meta
          name="description"
          content="Welcome to Aradhya Gems by Dr. Sridev Shastri, Best Astrologer in Howrah. A President Awardee Astrologer.
"
        />
      </Helmet>
      <div className="flex font-quattrocento w-full h-full flex-col  overflow-x-hidden ">
        <header className="    bg-white z-[60] w-full">
          <div className="flex  justify-between items-center sm:gap-2 lg:gap-0 lg:flex-row p-4 px-8">
            <div className="">
              <img
                src="images/sitelogo.png"
                alt=""
                className="h-[4rem] w-[15rem]"
              />
            </div>
            <div className="flex gap-4 text-black font-semibold sm:text-xs  md:text-lg">
              <Link
                to="https://payu.in/pay/7094D4D6A58E5D17B27701CBEAA6FE81 "
                className="sm:w-[10rem] rounded-sm md:w-[15rem] p-2 h-[3rem] bg-black text-yellow-400 hover:text-xl flex justify-center gap-4 items-center"
              >
                <span>Pay Now</span>
                <span>
                  <img src="images/topnav1.svg" alt="" className="w-8 h-8" />
                </span>
              </Link>
            </div>
          </div>
        </header>
        <Banner />
        <div className="w-full lg:p-16 sm:p-4 md:p-8 flex justify-center items-center">
          <div className="grid lg:grid-cols-2 sm:grid-cols-1 sm:gap-2 lg:gap-5 gap-x-20 place-content-center items-center">
            {equipments.map((equipment, index) => (
              <div
                className="flex sm:flex-col text-center lg:flex-row items-center  gap-2  lg:text-xl font-semibold sm:text-xl "
                key={index}
              >
                <div>
                  <img
                    src={equipment.icons}
                    alt=""
                    className="md:h-16 md:w-16 sm:w-12 sm:h-12"
                  />
                </div>
                <div>{equipment.text}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex md:flex-row sm:flex-col gap-2">
          <form
            onSubmit={handleSubmit}
            className="flex flex-col md:gap-2 sm:w-full md:w-[50%] items-center justify-center sm:gap-2 lg:gap-4 lg:px-16 sm:px-4 md:px-8 "
          >
            <span
              className="flex justify-center snm:text-sm lg:text-xl font-semibold  items-center
            "
            >
              Book Consultation
            </span>
            <span className="flex flex-col sm:w-full md:w-[90%] lg:w-[80%] gap-1">
              <input
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Name*"
                type="text"
                className="w-full h-[3rem] bg-red-100 border-2 border-yellow-400 p-4 placeholder-red-800 rounded-lg"
              />
            </span>
            <span className="flex flex-col gap-1  sm:w-full md:w-[90%] lg:w-[80%]">
              <input
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Mob No*"
                type="text"
                className="w-full h-[3rem] bg-red-100 border-2 border-yellow-400 p-4 placeholder-red-800 rounded-lg"
              />{" "}
            </span>
            <span className="flex flex-col gap-1  sm:w-full md:w-[90%] lg:w-[80%]">
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                type="text"
                placeholder="Messege (Optional )"
                className="w-full max-h-[6rem] min-h-[6rem] bg-red-100 border-2 border-yellow-400 p-4 placeholder-red-800 rounded-lg"
              />
            </span>
            <span className=" sm:w-full md:w-[90%] lg:w-[30%] flex justify-center transition-transform duration-300 hover:scale-105 items-center">
              <button
                type="submit"
                className="w-[6rem] sm:h-[3rem] lg:h-[2rem] border-[1px] text-black sm:text-lg md:text-sm lg:text-xl font-bold border-gray-300 bg-red-600 flex justify-center items-center rounded-lg"
              >
                Submit
              </button>
            </span>
          </form>
        </div>
        <AboutSection />
        <ServicesSection
          selectedServices={[
            "Janam Kundali Chart",
            "Couple Match Making",
            "Online Consultation",
            "Family Consultation",
            "Corporate Astrology",
            "Career Astrology",
            "Marriage Astrology",
            "Health Astrology",
          ]}
          serviceheading={"Our Services"}
        />
        <GallarySection showHeading={true} />
        <NewsFeaturedSection showHeading={true} />
        <AwardSection />
        <TestimonialSection />
        <footer>
          <div className="bg-black">
            <div className="lg:p-16 sm:p-4 md:p-8 h-full ">
              <div className="grid lg:grid-cols-3 sm:grid-cols-1 sm:gap-8 lg:gap-16 ">
                <div className="md:w-[85%] sm:w-full  h-fit rounded-lg shadow-xl text-yellow-400  flex flex-col gap-6">
                  <div>
                    <img
                      src="images/footerlogo.png"
                      alt=""
                      className="w-[10rem] h-auto"
                    />
                  </div>
                  <div className="flex gap-2 md:text-xl sm:text-sm items-start">
                    <span>
                      <FaLocationDot />
                    </span>
                    <span>
                      10/2A, Tusti Apartment, Nepal Bhattacharjee Street, Anami
                      Sangha, Kalighat, Kolkata, West Bengal 700026
                    </span>
                  </div>
                  <div className="flex gap-2 items-center md:text-xl sm:text-sm">
                    <span>
                      <MdOutgoingMail />
                    </span>
                    <Link to="mailto:astrologersridev@gmail.com">
                      astrologersridev@gmail.com
                    </Link>
                  </div>
                  <div className=" flex gap-2 items-center md:text-xl sm:text-sm">
                    <span>
                      <FaPhoneAlt />
                    </span>
                    <div className="flex lg:flex-col sm:flex-row xl:flex-row gap-2">
                      <Link to="tel:9051822756">+91 9051822756 /</Link>

                      <Link to="tel:7980787603">+91 7980787603</Link>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col justify-center gap-2 items-center">
                    <span className="text-yellow-400">We Are Available</span>
                    <div className="flex gap-4 text-xl">
                      <Link
                        to="https://api.whatsapp.com/send?phone=9051822756"
                        className="h-8 w-8 flex items-center justify-center rounded-full bg-yellow-400"
                      >
                        <FaWhatsapp />
                      </Link>
                      <Link
                        to="https://www.youtube.com/@drsridev"
                        className="h-8 w-8 flex items-center justify-center rounded-full bg-yellow-400"
                      >
                        <FaYoutube />
                      </Link>
                      <Link
                        to={
                          "https://www.instagram.com/drsridev?igsh=MTlpanh4cGFvejBydw=="
                        }
                        className="h-8 w-8 flex items-center justify-center rounded-full bg-yellow-400"
                      >
                        <FaInstagram />
                      </Link>
                      <Link
                        to={
                          "https://in.linkedin.com/in/dr-sridev-shastri-best-astrologer-in-kolkata-6469111ab"
                        }
                        className="h-8 w-8 flex items-center justify-center rounded-full bg-yellow-400"
                      >
                        <FaLinkedinIn />
                      </Link>
                      <Link
                        to={"https://twitter.com/shastrisridev"}
                        className="h-8 w-8 flex items-center justify-center rounded-full bg-yellow-400"
                      >
                        <FaXTwitter />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-4">
                  {" "}
                  <Link
                    to="https://payu.in/pay/7094D4D6A58E5D17B27701CBEAA6FE81 "
                    className="md:flex sm:hidden lg:w-[75%] md:w-[50%] sm:w-[75%]  p-4 justify-between items-center border text-xl font-semibold text-yellow-400 border-yellow-400 rounded-lg"
                  >
                    <span>Pay Now</span>
                    <span>
                      <MdOutlinePayment />
                    </span>
                  </Link>
                  <Link
                    to="/"
                    className="lg:w-[75%] md:w-[50%] sm:w-[75%]  p-4   border text-xl font-semibold text-yellow-400 border-yellow-400 rounded-lg  flex items-center justify-between"
                  >
                    <span>Go To Home Page</span>
                    <span>
                      <RiContractRightLine />
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="flex md:flex-row md:h-fit sm:h-[9rem] sm:flex-col sm:text-xs md:text-lg lg:text-xl sm:justify-start items-center md:justify-between bg-black border-t border-yellow-400  sm:p-2 md:p-6 ">
            <div className="  text-yellow-400    flex   ">
              Copyright © Dr. Sridev Shastri 2012-24
            </div>
            <div className="  text-yellow-400      flex gap-2  ">
              <span>Developed By:</span>
              <Link to="https://rebootmarketing.in/">
                Reboot Marketing Pvt. Ltd.
              </Link>
            </div>
          </div>
        </footer>
        <div>
          <OnlyMobile />
        </div>
      </div>
    </HelmetProvider>
  );
};

export default AddPageBestAstrologer;
