import React from "react";
import MainPageTemplate from "../element/MainPageTemplate";
import Banner from "../component/Banner";
import EnquirySection from "../component/EnquirySection";
import FooterSection from "../component/FooterSection";
import { Helmet, HelmetProvider } from "react-helmet-async";

const AwardsAndAchievements = () => {
  const awards = [
    {
      title:
        "Bharat Ratna Dr. B.R Ambedkar Award Recieved by Honorable President Of India Sri. Ramnath Kobind, Delhi",
    },
    { title: "Best Celebrity Astrologer by Actress Madhuri Dixit." },
    {
      title:
        "Lokshahi Sonman 2023 by Honorable Chief Minister of Maharastra Sr. Eknath Shinde.",
    },
    {
      title:
        "Bagdhara Sonman 2023 by Honourable Governor of Kerala Sri. Arif Mohammad Khan.",
    },
    { title: "Most Trusted Astrologer in India Award by Union Ministers." },
    {
      title:
        "Super Indians 24 Award by Union Minister Mr. Jitendra Singh & Celebrity Actor Mr. Govinda",
    },
    { title: "Asian Distinguished Award  2018 – Gold Medal(Singapore)" },
    { title: "Global Achievers Award 2018 (Kolkata)" },
    { title: "Tripura Jyotish  Ratna Award  2018 (Tripura)" },
    { title: "Jyotish Padmavusan Award 2018 ( Tripura)" },
    { title: "Sera Jyotish Sanman 2018 (Kolkata)" },
    { title: "Mata Tripuireswari Award" },
    {
      title:
        "Maharshi Parashar & Samudra Rishi Award Winner 2012(Kathmandu, Nepal)",
    },
    { title: "Jyotish Podmashree & Bharat Alankar Award Winner(New Delhi)" },
    { title: "Jyotish Samrat" },
    { title: "Tantra Siromoni" },
    { title: "Jyotish Bharati & Jyotish Shastri" },
    { title: "Jyotish Guru & Samudrik Ratna and Ratna Specialist" },
    { title: "Global fame award 2021 Kolkata" },
    { title: "International glory award 2021. Goa" },
    { title: "Golden glory award 2021 Mumbai" },
    { title: "Asia pacific excellence award 2021. New Delhi" },
    { title: "International business leadership award 2021. Goa" },
  ];

  return (
    <HelmetProvider>
      <Helmet>
        <title>Awards And Achievements</title>
        <meta
          name="description"
          content="Welcome to Aradhya Gems by Dr. Sridev Shastri, Best Astrologer in Howrah. A President Awardee Astrologer.
"
        />
      </Helmet>
      <MainPageTemplate>
        <Banner />
        <div className="flex lg:flex-row sm:flex-col sm:p-4 md:p-8 lg:p-16">
          <div className="flex flex-col gap-6 lg:w-[70%] sm:w-full sm:p-2 lg:p-8">
            <div className="flex flex-col gap-2 md:text-lg sm:text-base font-semibold text-red-600">
              {awards.map((award, index) => (
                <div key={index} className="flex gap-1 items-center">
                  <span>{award.title}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="lg:w-[30%] sm:w-full">
            <EnquirySection />
          </div>
        </div>
        <FooterSection />
      </MainPageTemplate>
    </HelmetProvider>
  );
};

export default AwardsAndAchievements;
