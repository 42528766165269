import React from "react";

const NotFound = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="max-w-lg text-center">
        <h1 className="text-4xl font-bold text-gray-800">404</h1>
        <p className="mt-2 text-lg font-medium text-gray-600">
          Oops! Page not found.
        </p>
        <p className="mt-1 text-gray-500">
          The page you are looking for does not exist.
        </p>
        <a href="/" className="mt-4 text-indigo-600 hover:underline">
          Go to Home Page
        </a>
      </div>
    </div>
  );
};

export default NotFound;
