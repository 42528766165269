import React from "react";
import MainPageTemplate from "../element/MainPageTemplate";

import ServicePageBanner from "../component/ServicePageBanner";
import NewsFeaturedSection from "../component/NewsFeaturedSection";
import FooterSection from "../component/FooterSection";
import { Helmet, HelmetProvider } from "react-helmet-async";

const PressRelease = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Press Release</title>
        <meta
          name="description"
          content="Welcome to Aradhya Gems by Dr. Sridev Shastri, Best Astrologer in Howrah. A President Awardee Astrologer.
"
        />
      </Helmet>
      <MainPageTemplate>
        <ServicePageBanner />
        <NewsFeaturedSection isactive={true} />
        <FooterSection />
      </MainPageTemplate>
    </HelmetProvider>
  );
};

export default PressRelease;
